import { animationCurve } from "./tokens";
import colors from "./colors";

const buttonBase = {
	cursor: "pointer",
	outline: "none",
	borderRadius: "3px",
	padding: "0.25em 1em",
	transition: `all 0.15s ${animationCurve}`,
};

const buttons = {
	primary: {
		...buttonBase,
		color: colors.white,
		backgroundColor: colors.primary,
		border: "solid 1px " + colors.primary,
		"&:hover": {
			backgroundColor: colors.secondary,
			borderColor: colors.secondary,
			color: colors.white,
		},
		"&:focus": {
			boxShadow: `0px 0px 0px 3px ${colors.alphaTint}`,
		},
	},
	secondary: {
		...buttonBase,
		color: colors.white,
		backgroundColor: colors.secondary,
		border: "solid 1px " + colors.secondary,
		"&:hover": {
			backgroundColor: colors.white,
			borderColor: colors.secondary,
		},
		"&:focus": {
			boxShadow: `0px 0px 0px 3px ${colors.alphaTint}`,
		},
	},
	hollow: {
		...buttonBase,
		color: colors.primary,
		backgroundColor: "transparent",
		border: "solid 1px " + colors.primary,
		"&:hover": {
			backgroundColor: colors.primary,
			color: colors.white,
		},
		"&:focus": {
			boxShadow: `0px 0px 0px 3px ${colors.alphaTint}`,
		},
	},
	subdued: {
		...buttonBase,
		color: colors.primary,
		backgroundColor: colors.primaryTint,
		"&:hover": {
			backgroundColor: colors.secondary,
			color: colors.white,
		},
		"&:focus": {
			boxShadow: `0px 0px 0px 3px ${colors.alphaTint}`,
		},
	},
	ghost: {
		color: colors.white,
		backgroundColor: "transparent",
		border: "solid 1px " + colors.white,
		"&:hover": {
			backgroundColor: colors.white,
			color: colors.secondary,
		},
	},
};

export default buttons;
