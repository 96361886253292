import { base } from "@theme-ui/presets";
import { space, fonts, fontSizes, lineHeights, letterSpacings } from "./tokens";
import colors from "./colors";
import text from "./textStyles";
import buttons from "./buttons";
import { baseStyles as styles } from "./baseCSS";

export default {
	...base,
	space,
	colors,
	fonts,
	fontSizes,
	lineHeights,
	letterSpacings,
	text,
	buttons,
	sizes: {
		container: "1440px",
	},
	styles,
};
