// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-author-template-js": () => import("./../../../src/templates/AuthorTemplate.js" /* webpackChunkName: "component---src-templates-author-template-js" */),
  "component---src-templates-blocks-template-js": () => import("./../../../src/templates/BlocksTemplate.js" /* webpackChunkName: "component---src-templates-blocks-template-js" */),
  "component---src-templates-list-template-js": () => import("./../../../src/templates/ListTemplate.js" /* webpackChunkName: "component---src-templates-list-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

