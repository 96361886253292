const headings = {
	heading: {
		fontFamily: "heading",
		textDecoration: "none",
		color: "heading",
	},
	headingSuper: {
		fontSize: [6, 7],
		lineHeight: "heading",
		marginBottom: [1, 2],
		color: "heading",
		maxWidth: "12em",
		letterSpacing: "-0.01em",
	},
	headingXL: {
		fontSize: [5, 6],
		lineHeight: "heading",
		marginBottom: [1, 2],
		color: "heading",
		maxWidth: "25em",
	},
	headingL: {
		fontSize: [4, 5],
		lineHeight: "heading",
		marginBottom: [1, 2],
		color: "heading",
	},
	headingM: {
		fontSize: [3, 4],
		lineHeight: "heading",
		marginBottom: [1, 2],
		color: "heading",
	},
	headingS: {
		fontSize: [2, 3],
		lineHeight: "heading",
		marginBottom: [0, 1],
		color: "heading",
	},
	headingXS: {
		fontSize: [1, 2],
		fontWeight: "normal",
		textTransform: "uppercase",
		letterSpacing: "expanded",
		lineHeight: "heading",
		marginBottom: [0, 1],
		color: "text",
	},
};

const bodyText = {
	bodyBase: {
		fontSize: [1, 2],
		fontFamily: "body",
		color: "text",
	},
	bodyXSmall: {
		fontSize: [0, 1],
		fontFamily: "body",
		color: "text",
		letterSpacing: "0.05em",
	},
	bodySmall: {
		fontSize: [1, 2],
		fontFamily: "body",
		color: "text",
		letterSpacing: "0.025em",
	},
	bodyLarge: {
		fontSize: [3, 4],
		fontFamily: "body",
		fontWeight: "400",
		letterSpacing: "0.025em",
		color: "text",
		maxWidth: "40em",
	},
};

const articles = {
	postHeading: {
		fontSize: [2, 3],
		lineHeight: 1.35,
		fontFamily: "accent",
		fontWeight: "normal",
		color: "primary",
		transition: "color 0.15s ease-in-out",
		"&:hover": {
			color: "secondary",
		},
	},
};

const text = { ...headings, ...bodyText, ...articles };

export default text;
