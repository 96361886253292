import { css } from "@emotion/core";
import { fonts, fontSizes, space, animationCurve } from "./tokens";
import colors from "./colors";

const baseStyles = {
	root: {
		fontFamily: "body",
		lineHeight: "body",
	},

	h1: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[6],
		marginBottom: space[4],
		color: colors.heading,
	},
	h2: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[5],
		marginBottom: space[4],
		color: colors.heading,
	},
	h3: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[4],
		marginBottom: space[4],
		color: colors.heading,
	},
	h4: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[3],
		marginBottom: space[3],
		color: colors.heading,
	},
	h5: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[2],
		marginTop: space[1],
		marginBottom: space[2],
		color: colors.heading,
	},
	h6: {
		fontFamily: fonts.heading,
		fontSize: fontSizes[1],
		marginTop: space[1],
		marginBottom: space[2],
		color: colors.heading,
	},
	p: {
		fontSize: fontSizes[2],
		marginBottom: space[3],
		maxWidth: "40em",
	},
	blockquote: {
		marginLeft: 0,
		marginTop: space[3],
		marginBottom: space[4],
		padding: 0,
	},
	ul: {
		paddingLeft: space[3],
	},
	a: {
		textDecoration: "none",
		color: colors.primary,
		transition: `color 0.15s ${animationCurve}, background-color 0.15s ${animationCurve}, border-color 0.15s ${animationCurve}`,
		"&:hover": {
			color: colors.hover,
		},
	},
	hr: {
		color: colors.subdued,
	},
};

const baseCSS = css`
	${baseStyles}
`;

export default baseCSS;
export { baseStyles };
