import "typeface-aileron";
import "typeface-archivo-narrow";
import "typeface-libre-baskerville";
import "typeface-lora";

const fonts = {
	body: "Aileron, sans-serif",
	heading: "Archivo Narrow, sans-serif",
	accent: "Lora, serif",
	monospace: "Menlo, monospace",
};

const space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

const fontSizes = [10, 13, 16, 18, 22, 32, 48, 72];

const lineHeights = {
	body: "1.575",
	heading: "1.125",
};

const letterSpacings = {
	tight: "-0.05em",
	normal: "0",
	expanded: "0.125em",
};

const animationCurve = "cubic-bezier(0,.22,.07,.97)";

export { fonts, fontSizes, lineHeights, letterSpacings, space, animationCurve };
