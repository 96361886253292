import { base } from "@theme-ui/presets";

const palette = {
	blue: "#0AA9D7",
	blueTint: "#EBF8FC",
	darkBlue: "#1F2258",
	yellow: "#D4FF14",
	white: "#FEFEFD",
	grayLight: "#F2F2F3",
	grayMedium: "#CACBCE",
	grayDark: "#61616B",
	black: "#1B1B23",
};

const colors = {
	...base.colors,
	// Colors
	primary: palette.blue,
	secondary: palette.darkBlue,
	accent: palette.yellow,
	primaryTint: palette.blueTint,
	alphaTint: `rgba(10, 169, 215, 0.4)`,
	// Grayscales
	white: palette.white,
	grayLight: palette.grayLight,
	grayMedium: palette.grayMedium,
	grayDark: palette.grayDark,
	black: palette.black,
	// Type
	text: palette.grayDark,
	heading: palette.black,
	active: palette.darkBlue,
	hover: palette.darkBlue,
	arrowColor: palette.grayMedium,
	// Other
	subdued: palette.grayLight,
	background: palette.white,
	footer: palette.white,
};

export default colors;
